
export default class EmpleadoService {
    constructor(axios) {
        this.axios = axios;
    }

    async getAllEmpleado(page = 1, pagesize = 10, searchterm = "") {
       
        return this.axios.get('/Empleado/', { 
            params: { 
                page: page,
                pagesize : pagesize,
                searchterm :   searchterm  === "" ? null : searchterm
            } 
        }).then(res => res.data);
    }


    async GetById(codigo){
        return this.axios.get(`/Empleado/${codigo}`).then(res => res.data);
    }
    
}
