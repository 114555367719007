<template>
     <PlainLayout >
          <template #header>
               <img src="@/assets/logo_login.png"/> 
          </template>
          <template #content>
               <form  onsubmit="return  false">
                    <div class="grid" v-if="!$store.state.uIsLoggedIn">
                         <div class="col-12">
                              <h1 class="font-bold p-0">{{appName.toUpperCase()}}</h1>
                              <h2 class="m-0">Bienvenido, por favor Inicia Sesión</h2>
                         </div>
                         <div class="col-12 py-3" >
                              <span class="p-float-label">
                                   <InputText class="text-center" type="text" id="username" v-model="username" autocomplete="off" :class="{'p-invalid': submitted && !username}"/>
                                   <label for="username">Usuario</label>
                              
                              </span>
                              <small class="p-error" v-if="submitted && !username">* Usuario no válido.</small>
                         </div>
                         <div class="col-12 py-3" >
                              <span class="p-float-label">  
                                   <InputText type="password" class="text-center" id="password"  v-model="password" autocomplete="off" :class="{'p-invalid': submitted && !password}"/>
                                   <label for="password" >Contraseña</label>
                                   
                              </span>
                              <small class="p-error" v-if="submitted && !password">* Contraseña no válida.</small>
                         </div>
                         <div class="col-12 ">
                              <Button type="submit" label="INICIAR"   class="font-bold w-full" @click="login_onClick"  :loading="loading"/>
                         </div>
                    </div>
               </form>
                    
               <transition name="fade" mode="out-in">
                    <div class="flex  flex-column" v-if="$store.state.uIsLoggedIn">
                         <h2 class="m-0">SELECCIONAR </h2>
                         <div  class="flex flex-shrink-0 py-2 " v-for="mod in $store.state.uConfig.cfModules" :key="mod">
                              <a href="#" class="btn btn-bg-light p-2 w-full hover:bg-primary text-gray-700 flex justify-content-center align-items-center" @click="selectModulo_onClick(mod)"> 
                                   <LayoutGridIcon class="mr-1 flex align-items-center"/> 
                                   <span class=" font-bold ">{{mod.mod_nombre}}</span>
                              </a>
                         </div>
                          
                    </div>
                   
               </transition>
          </template>
     </PlainLayout>
</template>

<script>
import PlainLayout from '@/layout/PlainLayout.vue';
import LayoutGridIcon from "@/components/svg/LayoutGridsvg.vue"

import  AuthService from '@/service/authService'
import  EmpleadoService from '@/service/empleadoService'
import GeneralService from '@/service/generalService'

export default {
     authService : null,
     empleadoService : null,
     generalService : null,
     components: { PlainLayout,LayoutGridIcon  },
     data() {
          return {
               appName : process.env.VUE_APP_NAME,
               username: "",
               password : "",
               submitted : false,
               loading: false,
               
          };
     },
     created(){
          this.generalService =  new GeneralService(this.axios)
          this.authService =  new AuthService(this.axios)
          this.empleadoService =  new EmpleadoService(this.axios)
     },
     async mounted(){
           
     },
     methods:{
          async fx_getCicloVigente(){
               var cicloList =  await this.generalService.getCiclo();
               var ciclo = null
                //Selected Ciclo Vigente
               if(cicloList.length > 0 ){
                    var vigente =  cicloList.findIndex(x=> x.cil_vigente =="S");
                    ciclo =  vigente <= -1 ? null : cicloList[vigente]
               }
               return ciclo;
          },
          async login_onClick(){
               this.loading =  true;
               this.submitted = true;
               if(!this.username || !this.password.trim()){
                    this.loading =  false;
                    return;
               }

               //Ciclo
               try{
                    var getCiclo = await this.fx_getCicloVigente().then();
                    await this.authService.login({
                         usuario: this.username,
                         acceso: this.password
                    }).then(async(res) => {
                         this.$appState.logMessageList = [] 
                         this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.token;
                         //param : creds (Object) =>  { token : Object , modulos : Array , usuarios :  Object, empleado : Object }
                         this.$store.dispatch("login",res).then(() =>{
                              //Set Module
                              this.$store.dispatch("userModule", res.modulos);  //set Modulos
                              this.loading =  false;
                              this.username =  "";
                              this.password = "";

                              //get empleado data.
                              var empleadoId = this.$store.getters.getEmpId
                              if( empleadoId != 0)
                                   this.empleadoService.GetById(empleadoId).then(res=>{
                                        this.$store.commit("setEmpleado",res);
                                   })

                              //set Ciclo
                              this.$store.dispatch("cicloActive", getCiclo);
                         });
                    })
               }catch(ex){
                    this.loading = false
                    var msg  =  ex
                    if(msg.response){
                         if(ex.response.status == 404)
                              this.$toast.add({severity:'error', summary:'No encontrado', detail:ex.response.data, life: 3000});
                    }
                    else
                         this.$toast.add({severity:'error', summary:'Error encontrar', detail:"Ha ocurrido un error al intentar acceder al servidor. " +msg, life: 3000});
               }
          },

          async selectModulo_onClick(module){
               await this.authService.logMenu({
                    cod_usuario: this.$store.state.uData.usr_codigo,
                    cod_modulo: module.mod_codigo
               }).then(res=> {
                    if(res == ""){
                         this.$toast.add({severity:'error', summary:'Menú no encontrado', detail:"Respuesta no contiene datos que cargar ", life: 3000});
                         return;
                    } 
                   this.$store.dispatch("createMenu", { module: module, menuList : res });
                   this.$router.push({name : "Home"})
               }).catch(ex => {
                    if(ex.response.status == 404)
                         this.$toast.add({severity:'error', summary:'Menú no encontrado', detail:ex.response.data, life: 3000});
               });
          }
     }
};
</script>
<style scoped lang="scss">

.p-inputtext {
    width:  100%;
    padding: 1rem 1rem
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
