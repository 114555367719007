
export default class GeneralService {
    constructor(axios) {
        this.axios = axios;
    }

    async getCiclo() {
        return this.axios.get('/Ciclo').then(res => res.data);
    }
}
